import React from "react"
import { Link, graphql } from "gatsby"

import GlobalLayout from "../layouts/global-layout"
import SEO from "../components/seo"


const Keywords = ({data}) => {
  return (
    <GlobalLayout>
      <SEO
        title="Keyword List"
        meta={[{
          name: 'robots',
          content: 'noindex',
        }]}
      />
      <h3 className="page-label">Keyword list</h3>
      <ul className="keyword-list">
        {data.allMdx.group.map(({fieldValue, totalCount}, idx) => {
          return (
            <li key={idx}>
              <Link to={`/keyword/${fieldValue}/`}>
                {fieldValue}: {totalCount}
              </Link>
            </li>
          )
        })}
      </ul>
    </GlobalLayout>
  )
}

export const query = graphql`
  {
    allMdx {
      group(field: frontmatter___keywords) {
        fieldValue
        totalCount
      }
    }
  }
`


export default Keywords